<template>
    <form @submit.prevent="submit()">
        <h3>{{ $t('crud.orders._singular') }} {{ record.reference }}</h3>
        <b-form-group :label="$t('crud.orders.shipping_method')">
            <b-form-select v-model="record.shipping_method">
                <option :value="null">{{ $t('crud.choose_option') }}</option>
                <option :value="1">{{ $t('crud.orders.shipping_methods.delivery') }}</option>
                <option :value="2">{{ $t('crud.orders.shipping_methods.pickup') }}</option>
            </b-form-select>
        </b-form-group>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        {{ $t('crud.orders.shipping_address') }}
                    </div>
                    <div class="card-body">
                        <b-form-group :label="$t('crud.orders.address.first_name')">
                            <b-form-input v-model="record.shipping_address.first_name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.last_name')">
                            <b-form-input v-model="record.shipping_address.last_name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.company')">
                            <b-form-input v-model="record.shipping_address.company"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.address_1')">
                            <b-form-input v-model="record.shipping_address.address_1"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.address_2')">
                            <b-form-input v-model="record.shipping_address.address_2"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.city')">
                            <b-form-input v-model="record.shipping_address.city"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.postal_code')">
                            <b-form-input v-model="record.shipping_address.postal_code"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.country')">
                            <b-form-input v-model="record.shipping_address.country"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.email')">
                            <b-form-input v-model="record.shipping_address.email"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.phone')">
                            <b-form-input v-model="record.shipping_address.phone"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        {{ $t('crud.orders.billing_address') }}
                    </div>
                    <div class="card-body">
                        <b-form-group :label="$t('crud.orders.address.first_name')">
                            <b-form-input v-model="record.billing_address.first_name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.last_name')">
                            <b-form-input v-model="record.billing_address.last_name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.company')">
                            <b-form-input v-model="record.billing_address.company"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.address_1')">
                            <b-form-input v-model="record.billing_address.address_1"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.address_2')">
                            <b-form-input v-model="record.billing_address.address_2"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.city')">
                            <b-form-input v-model="record.billing_address.city"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.postal_code')">
                            <b-form-input v-model="record.billing_address.postal_code"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.country')">
                            <b-form-input v-model="record.billing_address.country"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('crud.orders.address.email')">
                            <b-form-input v-model="record.billing_address.email"></b-form-input>
                        </b-form-group>
                        <b-form-checkbox v-model="delete_billing_address">
                            {{ $t('crud.orders.delete_billing_address') }}
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <b-form-group :label="$t('crud.orders.morning_delivery')">
            <b-form-select text-field="name" v-model="record.morning_delivery" :disabled="record.shipping_method === 2">
                <option :value="null">{{ $t('crud.choose_option') }}</option>
                <option :value="false">{{ $t('crud.no') }}</option>
                <option :value="true">{{ $t('crud.yes') }}</option>
            </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.note')">
            <b-form-textarea rows="3" v-model="record.note"></b-form-textarea>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.payment_method')">
            <b-form-select :options="payment_methods" v-model="record.payment_method_id" text-field="name" value-field="id">
                <template slot="first">
                    <option :value="null">{{ $t('crud.choose_option') }}</option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.transaction_id')">
            <b-form-input v-model="record.transaction_id"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.payment_completed_at')" placeholder="yyyy-mm-dd uu:mm:ss">
            <b-form-input v-model="record.payment_completed_at"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.confirmed_at')" placeholder="yyyy-mm-dd uu:mm:ss" :description="$t('crud.orders.confirmed_at_description')">
            <b-form-input v-model="record.confirmed_at"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.completed_at')" placeholder="yyyy-mm-dd uu:mm:ss">
            <b-form-input v-model="record.completed_at"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.orders.status')">
            <b-form-select :options="statuses" v-model="record.status" />
        </b-form-group>
        <b-form-group :label="$t('crud.orders.payment_status')">
            <b-form-select :options="payment_statuses" v-model="record.payment_status" />
        </b-form-group>
    </form>
</template>

<script>
    export default {
        props: ['record', 'loading'],
        mounted() {
            this.loadPaymentMethods();
        },
        data() {
            return {
                payment_methods: [],
                statuses: {
                    1: this.$t('crud.orders.statuses.1'),
                    2: this.$t('crud.orders.statuses.2'),
                    3: this.$t('crud.orders.statuses.3'),
                    4: this.$t('crud.orders.statuses.4'),
                    5: this.$t('crud.orders.statuses.5'),
                    6: this.$t('crud.orders.statuses.6'),
                    7: this.$t('crud.orders.statuses.7')
                },
                payment_statuses: {
                    1: this.$t('crud.orders.payment_statuses.1'),
                    2: this.$t('crud.orders.payment_statuses.2'),
                    3: this.$t('crud.orders.payment_statuses.3')
                },
                delete_billing_address: false
            }
        },
        watch: {
            delete_billing_address() {
                this.$emit('delete-billing-address', this.delete_billing_address);
            }
        },
        methods: {
            loadPaymentMethods() {
                return this.$http.get('payment-methods').then((response) => {
                    this.payment_methods = response.data;
                })
            },
            submit() {
                return true;
            }
        }
    }
</script>
