<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.orders.related_orders') }}</div>
        <div class="card-body">
            <table class="table table-sm table-striped">
                <tr>
                    <th>{{ $t('crud.reference') }}</th>
                    <th>{{ $t('crud.orders.confirmed_at') }}</th>
                </tr>
                <tr v-for="order in related_orders">
                    <td><router-link :to="{name: 'orders.show', params: { id: order.id}}">{{ order.reference}}</router-link></td>
                    <td>{{ order.confirmed_at | date }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['orderId'],
        mounted() {
            this.load();
        },
        data() {
            return {
                related_orders: []
            }
        },
        methods: {
            load() {
                return this.$http.get(`/orders/datatable?filters={\"related_orders\":${this.orderId}}`).then((response) => {
                    this.related_orders = response.data.data;
                });
            },
        }
    }
</script>
