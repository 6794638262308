<template>
    <div class="table-responsive">
        <table class="table">
            <tr>
                <th>{{ $t('crud.id') }}</th>
                <th>{{ $t('crud.order_line_deliveries.shipping_date') }}</th>
                <th>{{ $t('crud.order_line_deliveries.transporter') }}</th>
                <th>{{ $t('crud.order_line_deliveries.pallet') }}</th>
                <th>{{ $t('crud.order_line_deliveries.product') }}</th>
                <th>{{ $t('crud.order_line_deliveries.weight') }}</th>
                <th>{{ $t('crud.updated_at') }}</th>
                <th></th>
            </tr>
            <tr v-for="order_line_delivery in record.order_line_deliveries">
                <td>{{ order_line_delivery.id }}</td>
                <td>{{ order_line_delivery.shipping_date | date }}</td>
                <td>
                    <router-link
                            :to="{ name: 'transporters.show', params: { id: order_line_delivery.transporter_id } }">
                        {{ order_line_delivery.transporter.name }}
                    </router-link>
                </td>
                <td><small>{{ order_line_delivery.pallet_type }}</small></td>
                <td>{{ order_line_delivery.quantity }} ×
                    <router-link
                            :to="{ name: 'products.show', params: { id: order_line_delivery.product_id } }">
                        {{ order_line_delivery.product.name }}
                    </router-link>
                </td>
                <td>{{ order_line_delivery.weight }}</td>
                <td><small>{{ order_line_delivery.updated_at | date(true) }}</small></td>
                <td class="min">
                    <small v-if="order_line_delivery.transporter_dispatch" :title="order_line_delivery.transporter_dispatch.dispatch_key"><b><i class="fa fa-lock"></i> Verzonden {{ order_line_delivery.transporter_dispatch.created_at | date(true) }}</b></small>
                    <small v-else class="text-muted">Niet verzonden</small>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['record']
    }
</script>
