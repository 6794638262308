<template>
    <div>
        <div class="card-header">
            <i class="fa fa-file-text-o"></i> {{ $t('crud.orders.invoice.title') }}
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <th>{{ $t('crud.orders.invoice.product') }}</th>
                        <th width="8%">{{ $t('crud.orders.invoice.vat_percentage') }}</th>
                        <th width="8%">{{ $t('crud.orders.invoice.unit_price') }}</th>
                        <th width="8%">{{ $t('crud.orders.invoice.quantity') }}</th>
                        <th width="8%">{{ $t('crud.orders.invoice.price') }}</th>
                        <th style="width: 1px;" class="text-right" v-if="editable">
                            <button @click="show_new_model = !show_new_model"
                                    class="btn btn-sm btn-outline-secondary"><i class="fa fa-plus"></i>
                            </button>
                        </th>
                    </tr>
                    <transition name="fade">
                    <tr v-if="show_new_model">
                        <td>
                            <form id="add-order-line-form" @submit.prevent="$_addModel(new_model)"></form>
                            <b-form-select size="sm" :options="products" v-model="new_model.product_id" text-field="name" value-field="id" required form="add-order-line-form">
                                <template slot="first">
                                    <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                                </template>
                            </b-form-select>
                          <b-input-group size="sm">
                            <b-form-input v-model="new_model.note" form="add-order-line-form"></b-form-input>
                          </b-input-group>
                        </td>
                        <td>
                            <b-input-group prepend="%" size="sm" style="min-width: 100px">
                                <b-form-input v-model="new_model.vat_percentage" required form="add-order-line-form"></b-form-input>
                            </b-input-group>
                        </td>
                        <td><span v-if="!isNaN(new_model.price / new_model.quantity)">{{ new_model.price / new_model.quantity | toCurrency }}</span></td>
                        <td>
                            <b-input type="number" :placeholder="$t('crud.orders.invoice.quantity')" size="sm" v-model="new_model.quantity" required form="add-order-line-form"></b-input>
                        </td>
                        <td>
                            <b-input-group prepend="€" size="sm" style="min-width: 100px">
                                <b-form-input v-model="new_model.price" required form="add-order-line-form" @keypress.native="onlyNumber"></b-form-input>
                            </b-input-group>
                        </td>
                        <td class="text-right">
                            <loading-button class="btn btn-sm btn-outline-secondary" form="add-order-line-form"
                                            type="submit"

                                            :title="$t('crud.save')"
                                            :loading="loading_new_model"><i class="fa fa-save"></i></loading-button>
                        </td>
                    </tr>
                    </transition>
                    <tr v-for="(order_line, index) in record.order_lines" :class="order_line.product.name_key === 'refund' ? 'text-danger' : ''">
                        <td>
                            <form :id="'update-order-line-form-' + order_line.id" @submit.prevent="$_updateModel(order_line, index)"></form>
                            <template v-if="order_line.product.name_key === 'delivery'">
                                <router-link v-if="!order_line._editting" :to="{ name: 'products.show', params: { id: order_line.product.id } }">
                                    <!-- VIEWING TEMPLATE -->
                                    <template v-if="record.morning_delivery">{{ $t('crud.orders.morning_delivery_badge') }}</template>
                                    <template v-else>{{ order_line.product.name }} </template>
                                </router-link>
                                <template v-else>
                                    <!-- EDITTING TEMPLATE -->
                                    <router-link :to="{ name: 'products.show', params: { id: order_line.product.id } }">
                                        {{ order_line.product.name }}
                                    </router-link><br />
                                    <b-form-checkbox v-model="morning_delivery" :value="true" :disabled="record.shipping_method === 2" :unchecked-value="false">
                                        {{ $t('crud.orders.morning_delivery_badge') }}
                                    </b-form-checkbox>
                                </template>
                            </template>
                            <template v-else>
                                <router-link v-if="!order_line._editting" :to="{ name: 'products.show', params: { id: order_line.product.id } }">
                                    <!-- VIEWING TEMPLATE -->
                                    {{ order_line.product.name }}
                                  <small class="text-muted">{{ order_line.note }}</small>
                                </router-link>
                                <template v-else>
                                    <!-- EDITTING TEMPLATE -->
                                    <b-form-select size="sm" :options="products" v-model="order_line.product_id" text-field="name" value-field="id" required :form="'update-order-line-form-' + order_line.id">
                                        <template slot="first">
                                            <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                                        </template>
                                    </b-form-select>
                                    <b-input-group size="sm">
                                      <b-form-input v-model="order_line.note" :form="'update-order-line-form-' + order_line.id"></b-form-input>
                                    </b-input-group>
                                    <small v-if="order_line.product.name_key === 'refund'" class="text-muted">{{ order_line.created_at | date }}</small><br />
                                </template>
                            </template>
                        </td>
                        <td style="opacity: 0.5">
                            <template v-if="!order_line._editting">{{ parseFloat(order_line.vat_percentage).toFixed(2) }}%</template>
                            <b-input-group v-else prepend="%" size="sm" style="min-width: 100px">
                                <b-form-input v-model="order_line.vat_percentage" required :form="'update-order-line-form-' + order_line.id"></b-form-input>
                            </b-input-group>
                        </td>
                        <td>{{ order_line.unit_price | toCurrency }}</td>
                        <td>
                            <template v-if="!order_line._editting">× {{ order_line.quantity }}</template>
                            <b-input v-else type="number" :placeholder="$t('crud.orders.invoice.quantity')" @input="changeQuantity(order_line)" size="sm" v-model="order_line.quantity" required :form="'update-order-line-form-' + order_line.id"></b-input>
                        </td>
                        <th>
                            <template v-if="!order_line._editting">{{ order_line.price | toCurrency }}</template>
                            <b-input-group v-else prepend="€" size="sm" style="min-width: 100px">
                                <b-form-input v-model="order_line.price" required @input="changePrice(order_line)" :form="'update-order-line-form-' + order_line.id" @keypress.native="onlyNumber"></b-form-input>
                            </b-input-group>
                        </th>
                        <td class="text-right" v-if="editable">
                            <div class="btn btn-group btn-group-sm p-0">
                                <loading-button v-if="order_line._editting"
                                        :loading="order_line._loading"
                                        class="btn btn-sm btn-outline-secondary"
                                        type="submit"

                                        :title="$t('crud.save')"
                                        :form="'update-order-line-form-' + order_line.id"><i class="fa fa-save"></i></loading-button>
                                <button class="btn btn-sm btn-outline-secondary"
                                        v-if="!order_line._editting"
                                        @click="$set(order_line, '_editting', true)"
                                        :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                                <loading-button class="btn btn-sm btn-outline-danger"
                                        :loading="loading_models[index]"
                                        @click="deleteModel(order_line, index)"
                                        :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{{ $t('crud.orders.invoice.subtotal') }}</th>
                        <th>{{ record.price / (1 + vat_percentage) | toCurrency }}</th>
                        <th v-if="editable"></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{{ $t('crud.orders.invoice.vat', {percent: (vat_percentage * 100).toFixed(2)}) }}</th>
                        <th>{{ record.price / (1 + vat_percentage) * vat_percentage | toCurrency }}</th>
                        <th v-if="editable"></th>
                    </tr>
                    <tr class="table-secondary">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{{ $t('crud.orders.invoice.total') }}</th>
                        <th>{{ record.price | toCurrency }}</th>
                        <th v-if="editable"></th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import crud from '../../crud/mixin_table_crud';
    export default {
        props: ['record', 'editable'],
        mixins: [crud('order-lines')],
        created() {
            this.morning_delivery = this.record.morning_delivery;
        },
        mounted() {
            this.getProducts();
        },
        data() {
            return {
                products: [],
                new_model: {},
                morning_delivery: undefined
            }
        },
        computed: {
            /**
             * Use first order_line to calculate the VAT percentage for the whole invoice.
             * @returns {number}
             */
            vat_percentage() {
                if(this.record.order_lines.length) {
                    return this.record.order_lines[0].vat_percentage / 100;
                }
                return 0;
            },
            watch_product_id() {
               return this.new_model.product_id;
            }
        },
        watch: {
            watch_product_id(newVal) {
                // Set VAT-percentage from product, when a new one is selected.
                this.products.forEach((product) => {
                    if(product.id === newVal) {
                        this.new_model.vat_percentage = product.vat_percentage;
                        this.new_model.price = product.price;
                        if(product.name_key === 'fertilizer') {
                            // Assign quantity of grass product to the fertilizer.
                            let quantity = 0;
                            this.record.order_lines.filter((order_line) => {
                                if([1,2,3].indexOf(order_line.product_id) >= 0) {
                                    quantity = order_line.quantity;
                                }
                            });
                            this.new_model.price = quantity * product.price;
                            this.new_model.quantity = quantity;
                        }
                    }
                });
            }
        },
        methods: {
            changeQuantity(order_line)
            {
                this.$set(order_line, 'price', parseFloat((order_line.unit_price * order_line.quantity).toFixed(2)));
            },
            changePrice(order_line)
            {
                this.$set(order_line, 'unit_price', parseFloat((order_line.price / order_line.quantity).toFixed(2)));
            },
            resetModel() {
                this.new_model = Object.assign({}, {
                    order_id: this.record.id,
                    product_id: undefined,
                    note: undefined,
                    vat_percentage: undefined,
                    quantity: undefined,
                    price: undefined,
                });
            },
            $_addModel(model) {
                if(!confirm(this.$t('crud.orders.invoice_line_save_confirmation'))) {
                    return Promise.reject('Not confirmed');
                }
                return this.addModel(model);
            },
            /**
             * @param {Object} order_line
             * @param {Number} index
             * @return {Promise}
             */
            $_updateModel(order_line, index) {
                if(!confirm(this.$t('crud.orders.invoice_line_save_confirmation'))) {
                    return Promise.reject('Not confirmed');
                }
                if(order_line.product.name_key === 'delivery') {
                    // Update morning_delivery property if this order-line is changed.
                    return this.$http.put(`/orders/${this.record.id}`, {
                        morning_delivery: this.morning_delivery

                    }).then(() => {
                        this.updateModel(order_line);
                    });
                }
                if(index === 0) {
                    // Update order splits when first order_line is selected (should be the main grass product).
                    return this.updateOrderSplits(order_line).then(() => {
                        this.updateModel(order_line);
                    });
                }
                return this.updateModel(order_line);
            },
            /**
             * Update order splits using several properties from the order_line.
             * @return {Object} order_line
             * @return {Promise}
             */
            updateOrderSplits(order_line) {
                if(this.record.order_splits.length === 1) {
                    let order_split = this.record.order_splits[0];
                    return this.$http.put(`order-splits/${order_split.id}`, {
                        product_id: order_line.product_id,
                        quantity: order_line.quantity
                    }).then(() => {
                        this.$http.get(`products/${order_line.product_id}`).then((response) => {
                            order_split.product = response.data;
                            order_split.product_id = order_line.product_id;
                            order_split.quantity = order_line.quantity;
                        });
                    });
                } else {
                    return Promise.all(this.record.order_splits.map((order_split) => {
                        return this.$http.put(`order-splits/${order_split.id}`, {
                            product_id: order_line.product_id
                        }).then(() => {
                            this.$http.get(`products/${order_line.product_id}`).then((response) => {
                                order_split.product = response.data;
                                order_split.product_id = order_line.product_id;
                            });
                        });
                    }));
                }
            },
            /**
             * @return {Promise}
             */
            getProducts() {
                return this.$http.get('/products').then((response) => {
                    this.products = response.data;
                });
            }
        }
    }
</script>
