<template>
    <div class="table-responsive">
        <small v-if="quantityDifference !== 0" class="badge badge-danger mb-2"><i class="fa fa-exclamation-triangle"></i> {{ $t('crud.order_splits.incorrect_quantity', {difference: Math.abs(quantityDifference)}) }}</small>
        <table class="table table-sm">
            <tr>
                <th>{{ $t('crud.order_splits.shipping_date') }}</th>
                <th>{{ $t('crud.order_splits.pickup_location') }}</th>
                <th>{{ $t('crud.order_splits.transporter') }}</th>
                <th>{{ $t('crud.order_splits.quantity') }}</th>
                <th>{{ $t('crud.order_splits.product') }}</th>
                <th style="width: 1px;" class="text-right">
                    <button @click="show_new_model = !show_new_model"
                            class="btn btn-sm btn-outline-secondary"><i class="fa fa-plus"></i>
                    </button>
                </th>
            </tr>
            <transition name="fade">
                <tr v-if="show_new_model">
                    <td>
                        <form id="add-order-split-form" @submit.prevent="$_addModel(new_model)"></form>
                        <b-input-group size="sm">
                            <b-form-input type="date" v-model="new_model.shipping_date" required form="add-order-split-form"></b-form-input>
                        </b-input-group>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="pickup_locations" v-model="new_model.pickup_location_id" text-field="name" value-field="id" form="add-order-split-form">
                            <template slot="first">
                                <option :value="null">{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="transporters" v-model="new_model.transporter_id" required text-field="name" value-field="id" form="add-order-split-form">
                        </b-form-select>
                    </td>
                    <td>
                        <b-input-group size="sm">
                            <b-form-input type="number" v-model="new_model.quantity" required form="add-order-split-form"></b-form-input>
                        </b-input-group>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="products" v-model="new_model.product_id" text-field="name" value-field="id" required form="add-order-split-form">
                            <template slot="first">
                                <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td class="text-right">
                        <loading-button class="btn btn-sm btn-outline-secondary" form="add-order-split-form"
                                type="submit"

                                :title="$t('crud.save')"
                                :loading="loading_new_model"><i class="fa fa-save"></i></loading-button>
                    </td>
                </tr>
            </transition>
            <tr v-for="(order_split, index) in record.order_splits">
                <template v-if="!order_split._editting">
                    <td>{{ order_split.shipping_date | date }}</td>
                    <td>
                        <template v-if="order_split.pickup_location">{{ order_split.pickup_location.name }}</template>
                    </td>
                    <td>
                        <img v-if="order_split.transporter" style="max-height: 20px" :src="order_split.transporter.logo" :alt="order_split.transporter.name" :title="order_split.transporter.name" />
                    </td>
                    <td>× {{ order_split.quantity }}</td>
                    <td>{{ order_split.product.name }}</td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <small v-if="order_split.transporter_dispatch_id" class="text-muted">
                                Verzonden&nbsp;
                            </small>

                            <loading-button v-if="order_split._editting"
                                    :loading="order_split._loading"
                                    class="btn btn-sm btn-outline-secondary"
                                    type="submit"

                                    :title="$t('crud.save')"
                                    :form="'update-order-split-form-' + order_split.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!order_split._editting"
                                    @click="editOrderSplit(order_split)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                    :loading="loading_models[index]"
                                    @click="deleteModel(order_split, index)"
                                    :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
                <template v-else>
                    <td>
                        <form :id="'update-order-split-form-' + order_split.id" @submit.prevent="$_updateModel(order_split)"></form>
                        <b-input-group size="sm">
                            <b-form-input type="date"
                                  :value="$moment(order_split.shipping_date).format('YYYY-MM-DD')"
                                      @input="setShippingDate($event, order_split)"
                                  required :form="'update-order-split-form-' + order_split.id"></b-form-input>
                        </b-input-group>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="pickup_locations" v-model="order_split.pickup_location_id" text-field="name" value-field="id" :form="'update-order-split-form-' + order_split.id">
                            <template slot="first">
                                <option :value="null">{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="transporters" v-model="order_split.transporter_id" required text-field="name" value-field="id" :form="'update-order-split-form-' + order_split.id">
                        </b-form-select>
                    </td>
                    <td>
                        <b-input-group size="sm">
                            <b-form-input type="number" v-model="order_split.quantity" required :form="'update-order-split-form-' + order_split.id"></b-form-input>
                        </b-input-group>
                    </td>
                    <td>
                        <b-form-select size="sm" :options="products" v-model="order_split.product_id" text-field="name" value-field="id" required :form="'update-order-split-form-' + order_split.id">
                            <template slot="first">
                                <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                            </template>
                        </b-form-select>
                    </td>
                    <td class="text-right">
                        <div class="btn btn-group btn-group-sm p-0">
                            <loading-button v-if="order_split._editting"
                                    :loading="order_split._loading"
                                    class="btn btn-sm btn-outline-secondary"
                                    type="submit"

                                    :title="$t('crud.save')"
                                    :form="'update-order-split-form-' + order_split.id"><i class="fa fa-save"></i></loading-button>
                            <button class="btn btn-sm btn-outline-secondary"
                                    v-else
                                    @click="$set(order_split, '_editting', true)"
                                    :title="$t('crud.edit')"><i class="fa fa-pencil"></i></button>
                            <loading-button class="btn btn-sm btn-outline-danger"
                                    :loading="loading_models[index]"
                                    @click="deleteModel(order_split, index)"
                                    :title="$t('crud.delete')"><i class="fa fa-trash"></i></loading-button>
                        </div>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script>
    import crud from '../../crud/mixin_table_crud';

    export default {
        props: ['record'],
        mixins: [crud('order-splits')],
        mounted() {
            // @TODO validate form to prevent over-quantities
            this.resetModel();
            this.getProducts();
            this.getTransporters();
            this.getPickupLocations();
        },
        data() {
            return {
                products: [],
                transporters: [],
                pickup_locations: []
            }
        },
        computed: {
            /**
             * Check if the summed quantity of order splits matches the quantity of the first order line.
             * @return {Number}
             */
            quantityDifference() {
                let sum = this.record.order_splits.reduce( function(a, b){
                    return a + parseInt(b.quantity, 10);
                }, 0);
                return this.firstOrderLineQuantity - sum;
            },
            firstOrderLineQuantity()
            {
                if(this.record.order_lines.length) {
                    return this.record.order_lines[0].quantity;
                }
                return 0;
            }
        },
        methods: {
            $_addModel(model) {
                if(!confirm(this.$t('crud.order_splits.save_confirmation'))) {
                    return Promise.reject('Not confirmed');
                }
                return this.addModel(model);
            },
            $_updateModel(model) {
                if(!confirm(this.$t('crud.order_splits.save_confirmation'))) {
                    return Promise.reject('Not confirmed');
                }
                return this.updateModel(model);
            },
            resetModel() {
                this.new_model = Object.assign({}, {
                    order_id: this.record.id,
                    product_id: undefined,
                    quantity: undefined,
                    shipping_date: undefined,
                    transporter_id: undefined,
                    pickup_location_id: undefined
                });
            },
            getProducts() {
                return this.$http.get('products').then((response) => {
                    this.products = response.data;
                });
            },
            getTransporters() {
                return this.$http.get('transporters').then((response) => {
                    this.transporters = response.data;
                });
            },
            getPickupLocations() {
                return this.$http.get('pickup-locations').then((response) => {
                    this.pickup_locations = response.data;
                });
            },
            setShippingDate(value, order_split) {
                order_split.shipping_date = this.$moment(value, 'YYYY-MM-DD').format();
            },

            editOrderSplit(order_split) {
                this.$set(order_split, '_editting', !order_split._editting);
                order_split.transporter_dispatch_id = null;
            }

        }
    }
</script>
