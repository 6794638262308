<template>
    <div>
        <b-dropdown v-if="record" :disabled="record.payment_status === 1" class="mb-2" size="sm" variant="outline-secondary">
            <template slot="button-content">
                <template v-if="!loading_payment_url"><i class="fa fa-link"></i> {{ $t('crud.orders.generate_payment_url') }}</template>
                <template v-else><loading /></template>
            </template>
            <b-dropdown-item @click="generatePaymentUrl('mollie')">Mollie</b-dropdown-item>
            <b-dropdown-item @click="generatePaymentUrl('sofort')">Sofort</b-dropdown-item>
        </b-dropdown>
        <div role="tablist">
            <template v-for="order_response in order_responses">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-btn href="#" v-b-toggle="'accordion' + order_response.id" class="btn-block btn-secondary">{{ $moment(order_response.created_at).fromNow() }} ontvangen</b-btn>
                    </b-card-header>
                    <b-collapse :id="'accordion' + order_response.id" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <table id="order-responses" class="table table-sm" style="font-size: 0.8rem; background: transparent;">
                                <tr v-for="(val, key) in order_response.data">
                                    <th>{{ key }}</th>
                                    <td>{{ val }}</td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </template>
        </div>
        <loading v-if="loading"></loading>
        <template v-if="!loading && !order_responses.length">
            <p>{{ $t('crud.order_responses.none_found') }}</p>
        </template>
    </div>
</template>

<script>
    export default {
        props: ['record'],
        mounted() {
            this.load();
        },
        data() {
            return {
                order_responses: [],
                loading: true,
                loading_payment_url: false,
            };
        },
        methods: {
            /**
             * @param {String} provider 'mollie' or 'sofort'
             * @returns {Promise}
             */
            generatePaymentUrl(provider) {
                this.loading_payment_url = true;
                return this.$http.post('orders/' + this.record.id + '/generate-payment-url', {
                    provider
                }).then((response) => {
                    this.loading_payment_url = false;
                    alert(response.data.redirect);
                }).catch(() => { this.loading_payment_url = false; });
            },
            load() {
                this.loading = true;
                this.$http.get('orders/' + this.record.id + '/order-responses').then((response) => {
                    this.order_responses = response.data;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style>
    table#order-responses td, table#order-responses th {
        vertical-align: top;
    }
</style>
