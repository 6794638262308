<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.notes._plural') }}</div>
        <div class="card-body">
            <template v-if="!activity_logs">
                <loading></loading>
            </template>
            <template v-else>
                <transition-group name="fadeRight">
                    <div v-for="activity_log in activity_logs" :key="activity_log.id" :class="['alert', 'alert-' + getNoteClass(activity_log.event_name)]">
                        <template v-if="activity_log.event_name === 'mail.sent'">
                            <small>Mail <b>{{ activity_log.activity_parameters.mail }}</b> {{$t('crud.notes.sent')}}</small>
                        </template>

                        <template v-else-if="activity_log.event_name === 'model.updated'">
                            <small v-html="$t('crud.notes.changed_from_to', {
                                column: activity_log.activity_parameters.column,
                                original_value: activity_log.activity_parameters.originalValue === null ? '(empty)' :
                                translate(activity_log.activity_parameters.column, activity_log.activity_parameters.originalValue),
                                new_value: translate(activity_log.activity_parameters.column, activity_log.activity_parameters.newValue)
                            })"></small>
                        </template>

                        <template v-else-if="activity_log.event_name === 'note.regular'">
                            <small class="new-lines" v-html="activity_log.note"></small>
                        </template>

                        <template v-else-if="activity_log.event_name === 'note.notify'">
                            <small class="new-lines"><span v-html="activity_log.note"></span><br /><span class="text-primary"><i class="fa fa-envelope-o"></i> {{$t('crud.notes.email_sent')}}</span></small>
                        </template>

                        <template v-else-if="activity_log.event_name === 'delivery_issue.created'">
                            <small>
                                {{$t('crud.delivery_issues.sent')}} <br />
                                - {{$t('crud.delivery_issues.reason')}}: {{ $t('crud.delivery_issues.reason_' + activity_log.activity_parameters.reason) }}<br />
                                - {{$t('crud.delivery_issues.transporter_id')}}: {{ activity_log.activity_parameters.transporter_name }}<br />
                                <template v-if="activity_log.activity_parameters.quantity">
                                    - {{$t('crud.delivery_issues.quantity_short')}}: {{ activity_log.activity_parameters.quantity }}m²<br />
                                </template>
                                - Datum: {{ activity_log.activity_parameters.date }}
                            </small>
                        </template>

                        <template v-else>
                            <small>
                                <i class="fa fa-question-circle" v-b-tooltip :title="JSON.stringify(activity_log)"></i>
                            </small>
                        </template>

                        <div class="row">
                            <div class="col">
                                <small><span class="text-black-50">{{ activity_log.created_at | date(true) }}</span>
                                    <template v-if="activity_log.actor">
                                        {{ $t('crud.notes.created_by') }}
                                        <router-link :to="{ name: 'users.show', params: { id: activity_log.actor_id } }">
                                            <b>[{{ activity_log.actor.role_display_name }}] {{ activity_log.actor.username }}</b></router-link>
                                    </template>
                                </small>
                            </div>
                            <div class="col-auto text-right">
                                <small><button :class="['btn btn-xs btn-link', 'text-' + getNoteClass(activity_log.event_name)]" @click="deleteNote(activity_log)"><i class="fa fa-trash"></i></button></small>
                            </div>
                        </div>
                    </div>
                </transition-group>
                <p class="text-muted" v-if="!activity_logs.length">{{ $t('crud.notes.none_found') }}</p>
            </template>
            <hr />
            <form @submit.prevent="createNote">
                <b-form-group :label="$t('crud.notes.add_note')">
                    <b-form-textarea v-model="note_form.note" :placeholder="$t('crud.notes.note')" required></b-form-textarea>
                </b-form-group>
                <b-form-group>
                    <b-form-select v-model="note_form.private" :options="note_form.select_options"></b-form-select>
                </b-form-group>

                <loading-button :loading="loading_form" class="btn btn-primary pull-right">{{ $t('crud.save') }}</loading-button>
            </form>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['orderId'],
        mounted() {
            this.load();
            this.interval = setInterval(() => {
                if(document.hasFocus()) {
                    this.load();
                }
            }, 5000);
        },
        destroyed() {
            clearInterval(this.interval);
        },
        data() {
            return {
                interval: undefined,
                activity_logs: undefined,
                loading: true,
                loading_form: false,
                note_class: {
                    'note.notify': 'primary',
                    'note.regular': 'info',
                    'model.updated': 'secondary',
                    'model.created': 'secondary',
                    'mail.sent': 'secondary',
                    'delivery_issue.created': 'warning'
                },
                note_class_default: 'secondary',
                note_form: {
                    note: undefined,
                    private: true,
                    select_options: [
                        {value: false, text: this.$t('crud.notes.note_type_0')},
                        {value: true, text: this.$t('crud.notes.note_type_1')},
                    ]
                }
            }
        },
        methods: {
            getNoteClass(type) {
                return this.note_class[type] || this.note_class_default;
            },
            load() {
                this.$http.get('orders/' + this.orderId + '/activity-logs')
                    .then((response) => {
                        this.activity_logs = response.data;
                        this.loading = false;
                    }).catch(() => {
                    this.loading = false;
                })
            },
            createNote() {
                this.loading_form = true;
                this.$http.post('orders/' + this.orderId + '/activity-logs', {
                    note: this.note_form.note,
                    private: this.note_form.private
                }).then((response) => {
                    this.loading_form = false;
                    this.$toastr.s(this.$t('notifications.created', {object: this.$t('crud.notes._singular')}));
                    this.note_form.note = undefined;
                    // Refresh object.
                    this.load();
                }).catch(() => {
                    this.loading_form = false;
                });
            },
            deleteNote(activity_log)
            {
                let msg = this.$t('notifications.confirm_deletion', {object: this.$t('crud.notes._singular')});
                if(confirm(msg)) {
                    this.activity_logs.splice(this.activity_logs.indexOf(activity_log), 1);
                    this.$http.delete('orders/' + this.orderId + '/activity-logs/' + activity_log.id)
                        .then((response) => {
                            this.load();
                        });
                }
            },
            translate(column, value)
            {
                switch(column) {
                    case 'status':
                        return this.$t('crud.orders.statuses.' + value);
                    case 'payment_status':
                        return this.$t('crud.orders.payment_statuses.' + value);

                }
                return value;
            }
        }
    }
</script>
