<template>
    <div class="card">
        <div class="card-header">
            {{ $t('crud.orders.refund_payment') }}
        </div>
        <div class="card-body">
            <b-form-group label="Terugbetalingsbedrag">
                <b-input-group prepend="€">
                    <b-form-input :state="errors.amount ? false : undefined" v-model="form.amount" prepend="a" max="50" @keypress.native="onlyNumber" required></b-form-input>
                </b-input-group>
                <small v-if="errors.amount" class="text-danger">{{ errors.amount }}</small>
            </b-form-group>
            <b-form-group :label="$t('crud.orders.refund_reason')">
                <b-input placeholder="(optioneel)" v-model="form.reason"></b-input>
            </b-form-group>
            <b-button-group size="sm">
                <loading-button :loading="loading" class="btn btn-outline-secondary" @click="refundManually()">{{ $t('crud.orders.refund_manually', { amount: $options.filters.toCurrency(form.amount) }) }}</loading-button>
                <loading-button :loading="loading" class="btn btn-outline-secondary" @click="refundViaProvider()" v-if="refundableViaProvider">
                    {{ $t('crud.orders.refund_via_provider', { amount: $options.filters.toCurrency(form.amount), provider: record.payment_method.name }) }}
                </loading-button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['record'],
        mounted() {
            this.form.amount = this.record.price;
        },
        data() {
            return {
                form: {
                    amount: 0,
                    reason: undefined,
                },
                errors: {},
                loading: false
            }
        },
        computed: {
            refundableViaProvider() {
                let pm = this.record.payment_method;
                if(pm) {
                    return ['App\\PaymentSystem\\Mollie', 'App\\PaymentSystem\\Sofort'].indexOf(pm.handler_class) >= 0;
                }
                return false;
            }
        },
        methods: {
            reset() {
                this.form = {
                    amount: 0,
                    reason: undefined
                }
            },
            validate() {
                this.errors = {};
                if(this.form.amount > this.record.price) {
                    this.errors.amount = this.$t('crud.orders.refund_over_order_price', {price: this.$options.filters.toCurrency(this.record.price)});
                    return false;
                }
                return true;
            },
            refundManually()
            {
                if(!this.validate()) {
                    return;
                }
                if(confirm(this.$t('crud.orders.refund_payment_confirmation'))) {
                    this.loading = true;
                    let data = {
                        ...{ manual: true }, ...this.form
                    };
                    return this.$http.post('orders/' + this.record.id + '/refund-payment', data).then((response) => {
                        this.loading = false;
                        this.$emit('refunded', data);
                        let amount = this.$options.filters.toCurrency(data.amount);
                        this.reset();
                        this.$toastr.s(this.$t('crud.orders.refund_success', { amount }));
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            },
            refundViaProvider() {
                if(!this.validate()) {
                    return;
                }
                if(confirm(this.$t('crud.orders.refund_payment_confirmation'))) {
                    this.loading = true;
                    let data = {
                        ...{ manual: false }, ...this.form
                    };
                    return this.$http.post('orders/' + this.record.id + '/refund-payment', data).then((response) => {
                        this.loading = false;
                        this.$emit('refunded', data);
                        let amount = this.$options.filters.toCurrency(data.amount);
                        this.reset();
                        this.$toastr.s(this.$t('crud.orders.refund_success', { amount }));
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>
