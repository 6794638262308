    <template>
    <div v-if="this.loaded">
        <h2>{{$t('crud.orders._singular')}} {{ originalRecord.reference }} details</h2>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <h4>{{$t('crud.orders.general')}}</h4>
                <b-form-group :label="$t('crud.orders.payment_status')" label-class="font-weight-bold">

                    <b-form-select :options="select_lists.payment_statuses" v-model="record.payment_status">
                        <template slot="first">
                            <option :value="null" disabled>{{ $t('crud.choose_option') }}</option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group :label="$t('crud.orders.status')" label-class="font-weight-bold">
                    <b-form-select :options="select_lists.statuses" v-model="record.status">
                        <template slot="first">
                            <option :value="null" disabled>{{ $t('crud.choose_option') }}</option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <label class="font-weight-bold">{{ $t('crud.orders.confirmed_at') }}</label>
                <p>{{ originalRecord.confirmed_at | date(true) }}
                    <small class="text-muted">({{ $moment(originalRecord.confirmed_at).fromNow() }})</small></p>
            </div>
            <div class="col-lg-3 col-md-6">
                <h4>{{ $t('crud.orders.shipping_address') }} <button class="btn btn-sm btn-outline-secondary pull-right" @click="clickEditShippingAddress()"><i class="fa fa-pencil"></i></button></h4>
                <template v-if="edit_shipping_address">
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.shipping_address.company" :placeholder="$t('crud.orders.address.company')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.shipping_address.first_name" :placeholder="$t('crud.orders.address.first_name')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.shipping_address.last_name" :placeholder="$t('crud.orders.address.last_name')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.shipping_address.address_1" :placeholder="$t('crud.orders.address.address_1')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.shipping_address.address_2" :placeholder="$t('crud.orders.address.address_2')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.shipping_address.postal_code" :placeholder="$t('crud.orders.address.postal_code')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.shipping_address.city" :placeholder="$t('crud.orders.address.city')" size="sm"/></div>
                    </div>
                </template>
                <template v-else>
                    <p>
                        <template v-if="originalRecord.shipping_address.company">{{ originalRecord.shipping_address.company }}<br /></template>
                        {{ originalRecord.shipping_address.first_name }} {{ originalRecord.shipping_address.last_name }}<br/>
                        {{ originalRecord.shipping_address.address_1 }} {{ originalRecord.shipping_address.address_2 }}<br/>
                        {{ originalRecord.shipping_address.postal_code }} {{ originalRecord.shipping_address.city }}
                    </p>
                </template>

                <b>{{ $t('crud.orders.address.email') }}</b>
                <b-form-input v-if="edit_shipping_address" v-model="record.shipping_address.email" :placeholder="$t('crud.orders.address.email')" size="sm"/>
                <p v-else><a :href="'mailto:' + originalRecord.shipping_address.email">{{ originalRecord.shipping_address.email }}</a></p>
                <b>{{ $t('crud.orders.address.phone') }}</b>
                <b-form-input v-if="edit_shipping_address" v-model="record.shipping_address.phone" :placeholder="$t('crud.orders.address.phone')" size="sm"/>
                <p v-else><a :href="'tel:' + originalRecord.shipping_address.phone">{{ originalRecord.shipping_address.phone }}</a></p>
            </div>
            <div class="col-lg-3 col-md-6">
                <h4>{{ $t('crud.orders.billing_address') }} <button class="btn btn-sm btn-outline-secondary pull-right" @click="clickEditBillingAddress()"><i class="fa fa-pencil"></i></button></h4>

                <template v-if="edit_billing_address">
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.billing_address.company" :placeholder="$t('crud.orders.address.company')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.billing_address.first_name" :placeholder="$t('crud.orders.address.first_name')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.billing_address.last_name" :placeholder="$t('crud.orders.address.last_name')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.billing_address.address_1" :placeholder="$t('crud.orders.address.address_1')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.billing_address.address_2" :placeholder="$t('crud.orders.address.address_2')" size="sm"/></div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col"><b-form-input v-model="record.billing_address.postal_code" :placeholder="$t('crud.orders.address.postal_code')" size="sm"/></div>
                        <div class="col"><b-form-input v-model="record.billing_address.city" :placeholder="$t('crud.orders.address.city')" size="sm"/></div>
                    </div>
                </template>
                <template v-else>
                    <p v-if="Object.keys(originalRecord.billing_address).length">
                        <template v-if="originalRecord.billing_address.company">{{ originalRecord.billing_address.company }}<br /></template>
                        {{ originalRecord.billing_address.first_name }} {{ originalRecord.billing_address.last_name }}<br/>
                        {{ originalRecord.billing_address.address_1 }} {{ originalRecord.billing_address.address_2 }}<br/>
                        {{ originalRecord.billing_address.postal_code }} {{ originalRecord.billing_address.city }}
                    </p>
                    <p v-else class="text-muted">
                        {{$t('crud.orders.invoice_same_as_shipping_address')}}
                    </p>
                </template>
                <template v-if="originalRecord.shipping_method === 1">
                    <h6>{{ $t('crud.transporters._plural') }}</h6>
                    <p><template v-for="order_split in orderSplitsDistinct">
                        <template v-if="order_split.transporter">{{ order_split.transporter.name }}, </template>
                    </template></p>
                </template>
                <template v-else>
                    <h6>{{ $t('crud.pickup_locations._plural') }}</h6>
                    <p><template v-for="order_split in orderSplitsDistinct">
                        <template v-if="order_split.pickup_location">{{ order_split.pickup_location.name }}, </template>
                    </template></p>
                </template>
                <b>{{$t('crud.orders.note')}}</b>
                <b-form-textarea v-if="edit_billing_address" v-model="record.note" rows="3" :placeholder="$t('crud.orders.note')" size="sm"/>
                <p v-else>{{ originalRecord.note }}</p>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="card-body">
                        <h4>{{ $t('crud.orders.payment_details') }}</h4>
                        <b>{{ $t('crud.orders.payment_method') }}</b>
                        <p v-if="originalRecord.payment_method">{{ originalRecord.payment_method.name }}</p>
                        <p v-else class="text-muted">{{ $t('crud.orders.empty_payment_method') }}</p>

                        <b>{{ $t('crud.orders.payment_completed_at') }}</b>
                        <p v-if="originalRecord.payment_completed_at">
                            {{ originalRecord.payment_completed_at | date(true) }}
                            <small class="text-muted">({{ $moment(originalRecord.payment_completed_at).fromNow() }})</small>
                        </p>
                        <p v-else class="text-muted">{{ $t('crud.orders.empty_payment_completed_at') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col">
                <div class="card">
                    <invoice-part-component :record="record" @created="load" @updated="load" @deleted="load" :editable="editable"></invoice-part-component>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <transition name="fade">
                    <refund-part-component :record="record" v-if="show_refund && originalRecord.payment_method && originalRecord.payment_completed_at" @refunded="load"></refund-part-component>
                </transition>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col">
                <p class="text-right">
                    <loading-button class="btn btn-sm btn-outline-secondary mr-2" @click="show_refund = !show_refund" :disabled="!(originalRecord.payment_method && originalRecord.payment_completed_at)"><i class="fa fa-undo"></i> {{ $t('crud.orders.refund_payment') }}</loading-button>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card" v-if="originalRecord.shipping_method === 1">
                    <div class="card-header">
                        <i class="fa fa-truck"></i> {{ $t('crud.orders.delivery_details') }}
                    </div>
                    <div class="card-body">
                        <order-splits-part-component :record="record" @updated="load" @deleted="load" @created="load"></order-splits-part-component>
                        <order-line-deliveries-part-component :record="record" @update="load"></order-line-deliveries-part-component>
                    </div>
                </div>
                <div class="card" v-else>
                    <div class="card-header">
                        <i class="fa fa-building"></i> {{ $t('crud.orders.pickup_details') }}
                    </div>
                    <div class="card-body">
                        <order-splits-part-component :record="record" @updated="load" @deleted="load" @created="load"></order-splits-part-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {config} from '../../crud/mixin_config';
    import InvoicePartComponent from './Invoice';
    import OrderLineDeliveriesPartComponent from './OrderLineDeliveries';
    import OrderSplitsPartComponent from './OrderSplits';
    import RefundPartComponent from './Refund';

    export default {
        props: ['record', 'originalRecord'],
        components: {InvoicePartComponent, OrderLineDeliveriesPartComponent, OrderSplitsPartComponent, RefundPartComponent},
        created() {
            this.loaded = true;
        },
        data() {
            return {
                crud: config('orders'),
                select_lists: {
                    statuses: {
                        1: this.$t('crud.orders.statuses.1'),
                        2: this.$t('crud.orders.statuses.2'),
                        3: this.$t('crud.orders.statuses.3'),
                        4: this.$t('crud.orders.statuses.4'),
                        5: this.$t('crud.orders.statuses.5'),
                        6: this.$t('crud.orders.statuses.6'),
                        7: this.$t('crud.orders.statuses.7')
                    },
                    payment_statuses: {
                        1: this.$t('crud.orders.payment_statuses.1'),
                        2: this.$t('crud.orders.payment_statuses.2'),
                        3: this.$t('crud.orders.payment_statuses.3')
                    }
                },
                show_refund: false,
                loading: false,
                loaded: false,
                edit_shipping_address: false,
                edit_billing_address: false
            }
        },
        computed: {
            orderSplitsDistinct() {
                let arr = [];
                return this.originalRecord.order_splits.filter((item) => {
                    let distinct = arr.indexOf(item.transporter_id) === -1;
                    arr.push(item.transporter_id);
                    return distinct;
                });
            },
            /**
             * Decide whether order lines are able to be edited, based on the order status.
             */
            editable() {
                return true;
            }
        },
        methods: {
            load()
            {
                this.$emit('load', arguments);
            },
            clickEditShippingAddress() {
             if(this.edit_shipping_address) {
               this.edit_shipping_address = false;
               this.$emit('saveAddress');
             } else {
               this.edit_shipping_address = true;
             }
            },
          clickEditBillingAddress() {
            if(this.edit_billing_address) {
              this.edit_billing_address = false;
              this.$emit('saveAddress');
            } else {
              this.edit_billing_address = true;
            }
          }
        }
    }
</script>
