<template>
    <div class="row">
        <div class="col-9">
            <loading-card v-if="!loaded" :loading="loaded"></loading-card>
            <b-card v-else no-body class="mb-2">
                <b-tabs card @input="changeTab">
                    <b-tab :title="$t('crud.orders._singular') + ' details'" active>
                        <keep-alive>
                            <main-part-component :record="record" :original-record="original_record" v-if="active_tab_index === 0" @load="load" @saveAddress="_updateFromRoute"></main-part-component>
                        </keep-alive>
                    </b-tab>
                    <b-tab>
                        <template slot="title">
                            <i class="fa fa-pencil"></i> {{ $t('crud.edit') }}
                        </template>
                        <keep-alive>
                            <order-form-component @delete-billing-address="delete_billing_address = $event" :record="record" :loading="loading" v-if="active_tab_index === 1"></order-form-component>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Betalingsgegevens" :title-item-class="show_order_responses ? '' : 'd-none'">
                        <keep-alive>
                            <order-responses-part-component :record="record" v-if="active_tab_index === 2"></order-responses-part-component>
                        </keep-alive>
                    </b-tab>
                </b-tabs>
            </b-card>
            <button class="btn btn-sm btn-outline-secondary mb-2" title="Show developer tabs" @click="show_order_responses = !show_order_responses"><i class="fa fa-cog"></i> dev</button>
        </div>

        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <loading-button :loading="loading" class="btn btn-primary pull-right" @click="_updateFromRoute"><i class="fa fa-exclamation-circle" v-if="changed"></i> {{ $t('crud.save') }}</loading-button>
                    <loading-button class="btn btn-outline-danger pull-right mr-2" @click="removeFromRoute"><i class="fa fa-trash"></i></loading-button>
                </div>
            </div>
            <div class="card" v-if="loaded && original_record.order_invoices.length">
                <div class="card-body">
                    <table class="table table-sm m-0">
                    <tr v-for="order_invoice in original_record.order_invoices">
                        <td>
                            <a :href="readStorageFileLink(order_invoice.invoice_path)" target="_blank" :title="'Factuurnummer ' + order_invoice.invoice_id">
                                <i class="fa fa-file-pdf-o"></i>&nbsp;<span style="text-decoration: underline">{{ order_invoice.invoice_path }}</span>
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-primary">{{ order_invoice.invoice_type }}</span>
                        </td>
                        <td>
                            {{ order_invoice.created_at | date(true) }}
                        </td>
                    </tr>
                    </table>
                </div>
            </div>
            <notes-part-component ref="notes_part" :order-id="$route.params.id"></notes-part-component>
            <delivery-issues-part-component v-if="loaded" :order-id="$route.params.id" :prefill="{
                quantity: original_record.order_lines.length ? original_record.order_lines[0].quantity : undefined,
                transporter_id: original_record.order_splits.length ? original_record.order_splits[0].transporter_id : undefined,
                date: original_record.order_splits.length ? $moment(original_record.order_splits[0].shipping_date).format('YYYY-MM-DD') : undefined
            }" @created="$refs.notes_part.load(); load()"></delivery-issues-part-component>
            <div class="card">
                <div class="card-header">
                  {{$t('crud.orders.extra_actions')}}
                </div>
                <div class="card-body">
                    <button class="btn btn-sm btn-outline-primary" v-b-tooltip :title="$t('crud.orders.copy')" @click="copyOrder"><i class="fa fa-copy"></i></button>
                </div>
            </div>
            <related-orders-component :order-id="$route.params.id"></related-orders-component>
        </div>
    </div>
</template>
<script>
    import OrderResponsesPartComponent from './parts/OrderResponses';
    import MainPartComponent from './parts/Main';
    import NotesPartComponent from './parts/Notes';
    import DeliveryIssuesPartComponent from './parts/DeliveryIssues';
    import RelatedOrdersComponent from './parts/RelatedOrders';
    import {functions, config} from '../crud/mixin_config';
    import OrderFormComponent from './Form';

    export default {
        name: 'orders-show-component',
        mixins: [functions],
        components: {
            OrderResponsesPartComponent,
            MainPartComponent,
            NotesPartComponent,
            OrderFormComponent,
            DeliveryIssuesPartComponent,
            RelatedOrdersComponent
        },
        created() {
            // Add cookie for the duration of the browser session, because we open a PDF file directly from the API.
            // The default authentication is in the Authorization header (which is not available for GET-requests in this form).
            this.$cookie.set('token', this.$auth.token());
        },
        data() {
            return {
                crud: config('orders'),
                active_tab_index: 0,
                show_order_responses: false,
                delete_billing_address: false,
            };
        },

        watch: {
            record: {
                handler() {
                    // Prefill empty addresses.
                    if(!this.record.shipping_address) {
                        this.record.shipping_address = {};
                    }
                    if(!this.record.billing_address) {
                        this.record.billing_address = {};
                    }
                },
                deep: true,
                immediate: true
            },
            original_record: {
                handler() {
                    // Prefill empty addresses.
                    if(!this.original_record.shipping_address) {
                        this.original_record.shipping_address = {};
                    }
                    if(!this.original_record.billing_address) {
                        this.original_record.billing_address = {};
                    }
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            load() {
                functions.methods.load.call(this).catch((error) => {
                    if(error && error.response && error.response.status === 404) {
                        this.$toastr.i(this.$t('notifications.404', { object: this.$t('crud.orders._singular') }));
                        this.$router.push({name: 'orders.index'});
                    }
                });
            },
            changeTab(tabIndex) {
                this.active_tab_index = tabIndex;
            },
            copyOrder() {
                return this.$http.post(`orders/${this.record.id}/copy`).then((response) => {
                    // Redirect to newly created order.
                    this.$router.push({name: 'orders.show', params: { id: response.data.order_id }});
                });
            },
            _updateFromRoute() {
                if(Object.keys(this.record.shipping_address).length) {
                    this.record.shipping_address.type = 1;
                }
                if(Object.keys(this.record.billing_address).length) {
                    this.record.billing_address.type = 2;
                }
                if(this.delete_billing_address) {
                    this.record.billing_address = {};
                    this.$http.delete(`orders/${this.record.id}/billing-address`).then(() => {
                        this.delete_billing_address = false;
                        this.updateFromRoute();
                    });
                } else {
                    this.updateFromRoute();
                }
            },
            readStorageFileLink(path) {
                return this.$config.VUE_APP_API_URL + `/storage?path=${encodeURIComponent(path)}&cachebust=${Date.now()}`;
            }
        }
    }
</script>
