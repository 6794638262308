<template>
    <div class="card">
        <div class="card-header">{{ $t('crud.delivery_issues.title') }}</div>
        <div class="card-body">
            <form @submit.prevent="handle()">
                <b-form-group :label="$t('crud.delivery_issues.transporter_id')">
                    <b-form-select :options="transporters" text-field="name" value-field="id" v-model="form.transporter_id" size="sm" required>
                        <template slot="first">
                            <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <b-form-group :label="$t('crud.delivery_issues.reason')">
                    <b-form-select v-model="form.reason" size="sm" required>
                        <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                        <option :value="1">{{ $t('crud.delivery_issues.reason_1') }}</option>
                        <option :value="2">{{ $t('crud.delivery_issues.reason_2') }}</option>
                        <option :value="3">{{ $t('crud.delivery_issues.reason_3') }}</option>
                    </b-form-select>
                </b-form-group>

                <b-form-group :label="$t('crud.delivery_issues.quantity')" v-if="form.reason !== 1">
                    <b-form-input v-model="form.quantity" size="sm" type="number" required></b-form-input>
                </b-form-group>
                <p v-else>
                    <small class="text-primary"><i class="fa fa-envelope-o"></i> {{$t('crud.delivery_issues.send_to_customer')}}</small>
                </p>

                <b-form-group :label="$t('crud.delivery_issues.date')">
                    <b-form-input type="date" size="sm" v-model="form.date" required></b-form-input>
                </b-form-group>

                <loading-button :loading="loading_form" class="btn btn-primary pull-right">{{ $t('crud.delivery_issues.send') }}</loading-button>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['orderId', 'prefill'],
        mounted() {
            this.form = {...this.form, ...this.prefill};
            this.loadTransporters();
        },
        data() {
            return {
                transporters: [],
                form: {
                    order_id: this.orderId,
                    transporter_id: undefined,
                    reason: undefined,
                    quantity: undefined,
                    date: undefined
                },
                loading_form: false
            };
        },
        methods: {
            loadTransporters() {
                return this.$http.get('transporters').then((response) => {
                    this.transporters = response.data;
                });
            },
            handle() {
                this.loading_form = true;
                return this.$http.post(`orders/${this.orderId}/delivery-issue`, this.form).then(() => {
                    this.loading_form = false;
                    this.$toastr.s(this.$t('crud.delivery_issues.success'));
                    this.$emit('created');
                }).catch(() => {
                    this.loading_form = false;
                })
            }
        }
    }
</script>
